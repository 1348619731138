import React, { FC, PropsWithChildren } from "react";
import styles from "./AuthLayout.module.scss";
import logo from "../../../public/images/logo.png";
import immagine_intro from "../../../public/images/immagine_intro.png";
import Image from "next/image";
import { Layout } from "antd";
import PageFooter from "../page-footer";

const AuthLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Layout>
      <div className={styles.Content}>
        <div className={styles.Visual}>
          <Image
            alt="Logo Arvan"
            src={immagine_intro}
            className={styles.ImmagineIntro}
          />
        </div>

        <div className={styles.Body}>
          <div className={styles.Form}>
            <div className={styles.Logo}>
              <Image alt="Logo Arvan" src={logo} className={styles.Image} />
            </div>
            <div className={styles.Children}>{children}</div>
          </div>
        </div>
      </div>

      <Layout.Footer>
        <PageFooter />
      </Layout.Footer>
    </Layout>
  );
};

export default AuthLayout;
