import { NextPage } from "next";
import { useAccessToken } from "@/hooks/useAccessToken";
import { useMutation } from "@apollo/client";
import { LOGIN } from "@/graphql/mutations/login";
import { Form, Input, Button } from "antd";
import Link from "next/link";
import ErrorFeedback from "@/components/error-feedback";
import {
  LoginMutation,
  LoginMutationVariables,
} from "@/graphql/output/graphql";
import AuthLayout from "@/components/auth-layout";
import DocumentTitle from "@/components/document-title";

const Login: NextPage = () => {
  const [form] = Form.useForm();

  const [login, res] = useMutation<LoginMutation, LoginMutationVariables>(
    LOGIN
  );
  const [, setToken] = useAccessToken();

  const onFinish = (values: LoginMutationVariables) => {
    login({
      variables: {
        email: values.email,
        password: values.password,
      },
      onError: () => {},
      onCompleted: (res) => {
        setToken(res.login.access_token);
      },
    });
  };

  return (
    <AuthLayout>
      <DocumentTitle title={"Login"} />

      {res.error && <ErrorFeedback asAlert error={res.error} />}
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        onFinish={onFinish}
      >
        <Form.Item
          name="email"
          label="Email"
          rules={[
            { required: true, message: "Email richiesta" },
            { type: "email", message: "Inserisci un'email corretta" },
          ]}
        >
          <Input size="large" />
        </Form.Item>

        <Form.Item
          name="password"
          label="Password"
          rules={[{ required: true, message: "Password richiesta" }]}
          extra={
            <Link passHref href="/password-recovery">
              Recupero password
            </Link>
          }
        >
          <Input.Password size="large" />
        </Form.Item>

        <Button
          block
          size="large"
          type="primary"
          htmlType="submit"
          loading={res.loading}
        >
          Login
        </Button>
      </Form>
    </AuthLayout>
  );
};

export default Login;
