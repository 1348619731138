import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      access_token
      user {
        id
      }
    }
  }
`;

export const PASSWORD_RECOVERY = gql`
  mutation PasswordRecovery($email: String!) {
    passwordRecovery(email: $email)
  }
`;

export const PASSWORD_RESET = gql`
  mutation PasswordReset($data: PasswordResetInput!) {
    passwordReset(data: $data)
  }
`;

export const ACCEPT_INVITE = gql`
  mutation UserAcceptInvite(
    $token: String!
    $password: String!
    $password_confirmation: String!
  ) {
    userAcceptInvite(
      token: $token
      password: $password
      password_confirmation: $password_confirmation
    ) {
      id
    }
  }
`;
