import { FC } from "react";
import Head from "next/head";

interface DocumentTitleProps {
  title?: string | number | (string | undefined | number)[];
}

const DocumentTitle: FC<DocumentTitleProps> = ({ title = undefined }) => {
  let t = `Arvan`;

  if (title) {
    if (!Array.isArray(title)) {
      title = [title];
    }

    t = title.filter((t) => !!t).join(" - ") + " | " + t;
  }

  return (
    <Head>
      <title>{t}</title>
    </Head>
  );
};

export default DocumentTitle;
