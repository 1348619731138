import { Alert, Typography } from "antd";
import React, { FC, useMemo } from "react";

import { ApolloError } from "@apollo/client";
import classNames from "classnames";
import styles from "./ErrorFeedback.module.scss";

interface ErrorFeedbackProps {
  error: ApolloError;
  asAlert?: boolean;
  errorsText?: string;
}

const ErrorFeedback: FC<ErrorFeedbackProps> = ({
  error,
  asAlert = false,
  errorsText = undefined,
}) => {
  const isValidation = error.graphQLErrors.some(
    (err) => "validation" in err.extensions
  );

  const messageBag = useMemo(() => {
    if (!isValidation) {
      return error.message;
    }

    let messages: string[] = [];

    error.graphQLErrors.forEach((err) => {
      const validation = err.extensions?.validation;

      if (validation) {
        const fieldMsg = Object.values(validation as any) as string[];
        messages = [...messages, ...fieldMsg.flat()];
      }
    });

    return (
      <>
        <Typography.Text>
          {errorsText || "Si sono verificati i seguenti errori:"}
        </Typography.Text>

        <ul>
          {messages.map((msg, index) => (
            <li key={index}>{msg}</li>
          ))}
        </ul>
      </>
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValidation]);

  return !asAlert ? (
    <Typography.Text>{messageBag}</Typography.Text>
  ) : (
    <Alert
      type="warning"
      message={messageBag}
      className={classNames(styles.Alert, !isValidation && styles.General)}
    />
  );
};

export default ErrorFeedback;
